<template>
  <el-main>
    <el-form label-width="200px">
      <el-form-item label="有效连接赠送抽奖次数：">
        <el-switch v-model="is_open" :active-value="1" :inactive-value="0"></el-switch>
        <el-form-item v-if="is_open" label="每次有效连接赠送抽奖次数：">
          <el-input v-model="times">
            <template slot="append">次</template>
          </el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item label="抽奖说明：">
        <RichText :width="700" :height="500" :richTxt="content" @soninfo="val => (content = val)"></RichText>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      is_open: 1,
      times: '',
      content: '',
      is_add: !1,
    };
  },
  created() {
    this.$axios.post(this.$api.serviceProvider.luckyDrawSetInfo).then(res => {
      if (res.code == 0) {
        this.content = res.result.content;
        this.is_open = res.result.is_open;
        this.times = res.result.times;
      } else {
        this.is_add = !0;
      }
    });
  },
  methods: {
    preservation() {
      this.$axios
        .post(
          this.is_add ? this.$api.serviceProvider.luckyDrawAdd : this.$api.serviceProvider.luckyDrawSet,
          this.is_open ? { is_open: this.is_open, content: this.content, times: this.times } : { content: this.content, is_open: this.is_open }
        )
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
            this.is_add = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background: #fff;
  .el-form {
    width: 800px;
  }
}
</style>